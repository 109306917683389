import * as React from "react";
import "../../fonts/fonts.css";
import "../styles.scss";
import Header from "../../components/header";
import Footer from "../../components/footer";
import DATA from "../../data";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const COVERS_DATA = [
  {
    page: "bloor-one",
    caption: "The Penthouses – One Bloor",
  },
  {
    page: "forest-house-in-smolec",
    caption: "Forest house in Smolec",
  },
  {
    page: "mustang",
    caption: "Mustang '65",
  },
  {
    page: "apartment-by-the-baltic-sea",
    caption: "Apartment by the Baltic Sea",
  },
  {
    page: "town-bridge",
    caption: "Town Bridge",
  },
  {
    page: "skyview",
    caption: "Skyview project in Brewery District",
  },
  {
    page: "muskoka-resort",
    caption: "Resort in Muskoka",
  },
  {
    page: "peter-and-adelaide",
    caption: "Peter & Adelaide",
  },
  {
    page: "house-in-the-sun",
    caption: "House in the sun",
  },
  {
    page: "central-park-amenities",
    caption: "Central Park - Amenities",
  },
  {
    page: "lewisham-gateway",
    caption: "Lewisham Gateway",
  },
  {
    page: "77-mutual-street",
    caption: "77 Mutual Street",
  },
  {
    page: "house-in-new-zealand",
    caption: "House in New Zealand",
  },
  {
    page: "morning-commute",
    caption: "Morning commute",
  },
  {
    page: "ipn",
    caption: "Institute of National Remembrance",
  },
  {
    page: "bw-house-in-swidnica",
    caption: "B&W House in Swidnica",
  },
  {
    page: "ski-resort-in-blue-mountain",
    caption: "Ski resort in Blue Mountain",
  },
  {
    page: "misc",
    caption: "Miscellaneous",
  },
];

const prepareCovers = (coverNodes) => {
  const coversDictionary = {};
  coverNodes.forEach((cover) => {
    coversDictionary[cover.node.relativeDirectory] =
      cover.node.childImageSharp.gatsbyImageData;
  });
  return coversDictionary;
};

const GridComponent = ({ covers }) => {
  return (
    <div className="container">
      {COVERS_DATA.map(({ page, caption }) => {
        return (
          <Link to={`/portfolio/${page}`} key={`project-${page}`}>
            <figure>
              <GatsbyImage loading="lazy" image={covers[page]} alt={caption} />
              <figcaption>{caption}</figcaption>
            </figure>
          </Link>
        );
      })}
    </div>
  );
};

// markup
const PortfolioPage = (props) => {
  const covers = prepareCovers(props.data.allFile.edges);

  return (
    <main id="portfolio">
      <title>{DATA.title} - Portfolio</title>
      <Header withNavigation />
      <div className="content">
        <div className="inner">
          <GridComponent covers={covers} />
        </div>
      </div>
      <Footer />
    </main>
  );
};

export const query = graphql`
  query PortfolioPageQuery {
    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { regex: "/cover/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
          relativeDirectory
        }
      }
    }
  }
`;

export default PortfolioPage;
